import * as React from "react"
import {Footer} from "../components/landingpage/footer";
import {Header} from "../components/header";
import {TrainingChoice} from "../components/trainingChoice";
import {MetaTags} from "../components/metaTags";

const title = "Formation TDD et Clean Architecture par WealCome";
const metaDescription = "Chez WealCome, Michaël AZERHAD forme vos développeurs au TDD et à la Clean Architecture" +
    " à travers nos sessions de 14 heures, professionnels comme particuliers.";

export default () => {
    return (
        <div>
            <MetaTags title={title} description={metaDescription}/>
            <Header fixedTop={true} backgroundTransition={false}/>
            <div className="main">
                <TrainingChoice/>
            </div>
            <Footer/>
        </div>
    )
};
